import {useCallback, useEffect, useState} from "react";
import axios from "axios";
import TGKeycloak from "../keycloak/TGKeycloak";

const useFetchWishes = (initialStatus, initialSort) => {

    const [wishes, setWishes] = useState([{}])
    const [loaded, setLoaded] = useState(false)
    const [status, setStatus] = useState(initialStatus)
    const [sort, setSort] = useState(initialSort)

    const updateStatus = (newStatus) => {
        if (status !== newStatus) {
            setStatus(newStatus)
            return true
        }
        return false
    }

    const updateSort = (newSort) => {
        setSort(newSort)
    }

    const fetchWishes = useCallback(() => {
        axios.get(`${window._env_.REACT_APP_API_HOST}/api/v1/wishlist-items?status=` + status + `&sort=` + sort, {
            headers: {'Authorization': 'Bearer ' + TGKeycloak.token},
        })
            .then(response => {
                setWishes(response.data)
                setLoaded(true)
            })
    }, [status, sort]);

    useEffect(() => {
        fetchWishes()
    }, [fetchWishes])

    return {wishes, loaded, updateStatus, updateSort}
}

export default useFetchWishes;