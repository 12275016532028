import {useCallback, useEffect, useState} from "react";
import axios from "axios";
import TGKeycloak from "../keycloak/TGKeycloak";

const useFetchVotes = (wishesIds) => {

    const [votes, setVotes] = useState([{}])
    const [loaded, setLoaded] = useState(false)
    const [wishlistItemIds] = useState(extractWishesIds(wishesIds))

    function extractWishesIds(ids) {
        return ids === undefined
            ? []
            : [...wishesIds]
    }

    function getWishesIdsParam(wishesIds) {
        if (wishesIds === undefined || wishesIds.size === 0) {
            return ''
        } else {
            let ramParams = '?'
            wishesIds.forEach(wishesId => {
                ramParams += ('wishesIds=' + wishesId + '&')
            })
            return ramParams.substring(0, ramParams.length - 1)
        }
    }

    const fetchVotes = useCallback(() => {
        axios.get(`${window._env_.REACT_APP_API_HOST}/api/v1/votes` + getWishesIdsParam(wishlistItemIds), {
            headers: {'Authorization': 'Bearer ' + TGKeycloak.token},
        })
            .then(response => {
                setVotes(response.data)
                setLoaded(true)
            })
    }, [wishlistItemIds])

    useEffect(() => {
        fetchVotes()
    }, [fetchVotes])

    return {votes, setVotes, loaded}
}

export default useFetchVotes;