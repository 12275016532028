import './Main.css'
import '../Common.css'
import feature_1 from '../data/img/1_features_gradient_scaled.png'
import feature_2 from '../data/img/2_features_gradient_scaled.png'
import feature_3 from '../data/img/3_features_gradient_scaled.png'
import GetDataUri from "../util/StaticData";

function Feature() {
    return (
        <div className="feature-container">
            <div className="feature-container-header tg-main-header-text">
                TRADE GUARD
                <h1>CRYPTO RISK MANAGEMENT APPLICATION FEATURES</h1>
            </div>
            <div className="feature-item">
                <div className="feature-item-content">
                    <div className="feature-item-header tg-main-text">
                        <h2>Trading limits set up</h2>
                    </div>
                    <div className="feature-item-value tg-main-text">
                        Users can set limits for each trade as well as overall daily trading limits,
                        ensuring risk control. This feature allows traders to flexibly adjust their
                        risk management parameters according to their trading plan and strategy, preventing
                        unwanted losses and maintaining discipline in trading.
                    </div>
                </div>
                <img className="feature-item-img" src={GetDataUri(feature_1)}/>
            </div>
            <div className="feature-item">
                <img className="feature-item-img" src={GetDataUri(feature_2)}/>
                <div className="feature-item-content">
                    <div className="feature-item-header tg-main-text">
                        <h2>Trading lock for impulse protection</h2>
                    </div>
                    <div className="feature-item-value tg-main-text">
                        The service offers a trading lock feature that is activated after reaching
                        the daily loss limit. This prevents the possibility of opening new trades
                        during periods of high volatility or emotional imbalance (tilt), protecting
                        traders from impulsive decisions that could lead to additional losses.
                    </div>
                </div>
            </div>
            <div className="feature-item">
                <div className="feature-item-content">
                    <div className="feature-item-header tg-main-text">
                        <h2>Notifications upon reaching specified trade limits</h2>
                    </div>
                    <div className="feature-item-value tg-main-text">
                        Traders receive instant notifications when set limits for a trade or for the
                        day are reached. This provides timely information about the current state of
                        trading, allowing the user to quickly adjust their actions and make informed
                        decisions based on up-to-date financial results.
                    </div>
                </div>
                <img className="feature-item-img" src={GetDataUri(feature_3)}/>
            </div>
        </div>
    )
}

export default Feature;