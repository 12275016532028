const TGRoutesDefinition = new Map([
    ['Main', {path: '/', changefreq: 'monthly', priority: 1}],
    ['Home', {path: '/home', changefreq: 'monthly', priority: 0.7}],
    ['Settings', {path: '/settings', changefreq: 'monthly', priority: 0.7}],
    ['Wishlist', {path: '/wishlist', changefreq: 'monthly', priority: 0.7}],
    ['NewWishlist', {path: '/wishlist/new', changefreq: 'monthly', priority: 0.7}],
    ['WishlistItem', {path: '/wishlist/items/:wishId', ignore: true}],
    ['Contacts', {path: '/contacts', changefreq: 'monthly', priority: 0.7}],
    ['Faq', {path: '/faq', changefreq: 'monthly', priority: 0.7}],
])

module.exports = TGRoutesDefinition
