import '../Common.css'
import './NewWIshlist.css'
import React, {useState} from "react";
import axios from "axios";
import TGKeycloak from "../keycloak/TGKeycloak";
import {useNavigate} from "react-router-dom";

function NewWishlist() {
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const navigate = useNavigate()

    const handleNameChange = (event) => {
        setName(event.target.value)
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value)
    }

    const handleSubmit = () => {
        axios.post(`${window._env_.REACT_APP_API_HOST}/api/v1/wishlist-items`, {
            name: name,
            description: description
        }, {
            headers: {'Authorization': 'Bearer ' + TGKeycloak.token}
        })
            .then(() => {
                navigate('/wishlist')
            })
    }

    function redirectToList() {
        navigate('/wishlist')
    }

    return (
        <div className="container">
            <div className="content-container new-wish-container-wrapper">
                <div className="new-wish-header tg-header-text">New Wishlist Item</div>
                <i className="tg-back-button fa-solid fa-rotate-left" onClick={redirectToList}/>
                <div className="new-wish-container">
                    <div className="new-wish-prop-name tg-name-text">Name:</div>
                    <input className="tg-input" type="text" maxLength='150' onChange={handleNameChange}/>
                    <div className="new-wish-separator tg-hr2-separator"/>
                    <div className="new-wish-prop-name tg-name-text">Description:</div>
                    <textarea className="new-wish-description tg-input"
                              onChange={handleDescriptionChange}/>
                    <div className="new-wish-separator tg-hr2-separator"/>
                    <div className="new-wish-button-container">
                        <button className="new-wish-button tg-button" onClick={handleSubmit}>SUBMIT</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
NewWishlist.canonicalName = 'NewWishlist'

export default NewWishlist;