import './Home.css'
import React from "react";

function CurrentTradingStatus({tradingState, stateLoaded}) {

    function round(value) {
        return parseFloat(value.toFixed(4))
    }

    const {status} = tradingState

    function extractAdditionalInfo(status) {
        if (status === 'BLOCKED') {
            return 'until ' + tradingState.terminateGuardingTime + ' ' + tradingState.currentTimezone
        }
    }

    function extractCommissionValue() {
        if (stateLoaded === true && status === 'GUARDED') {
            return tradingState.options.withCommission
                ? 'true' : 'false';
        } else {
            return '—'
        }
    }

    function statusColor(status) {
        switch (status) {
            case 'NOT_GUARDED':
                return '#C5C7C5';
            case 'GUARDED':
                return '#28DE68';
            case 'BLOCKED':
                return '#DE3728';
            default:
                return 'black';
        }
    }

    function calculatePnL(pnlExtractor) {
        return stateLoaded === true && status === 'GUARDED'
            ? round(pnlExtractor())
            : '—';
    }

    return (
        <div className="status-element">
            <div className="status-header tg-header-text">Current Trading Status</div>
            <div className="status-prop-container">
                <div className="status-prop">
                    <div className="status-prop-name tg-name-text">Day limit:</div>
                    <div className="status-prop-value tg-value-text">
                        {stateLoaded === true && tradingState.options && tradingState.options.dayLimitValue
                            ? tradingState.options.dayLimitValue : '—'}
                    </div>
                </div>
                <div className="status-prop">
                    <div className="status-prop-name tg-name-text">Position limit:</div>
                    <div className="status-prop-value tg-value-text">
                        {stateLoaded === true && tradingState.options && tradingState.options.positionLimitValue
                            ? tradingState.options.positionLimitValue : '—'}
                    </div>
                </div>
                <div className="status-prop">
                    <div className="status-prop-name tg-name-text">Handle fees:</div>
                    <div className="status-prop-value tg-value-text">
                        {extractCommissionValue()}
                    </div>
                </div>
                <div className="status-separator tg-hr-separator"/>
                <div className="status-prop">
                    <div className="status-prop-name tg-name-text">Realized PnL:</div>
                    <div className="status-prop-value tg-value-text">
                        {calculatePnL(() => tradingState.currentRealizedPnL)}
                    </div>
                </div>
                <div className="status-prop">
                    <div className="status-prop-name tg-name-text">Unrealized PnL:</div>
                    <div className="status-prop-value tg-value-text">
                        {calculatePnL(() => tradingState.currentUnrealizedPnL)}
                    </div>
                </div>
                <div className="status-prop">
                    <div className="status-prop-name tg-name-text">Common PnL:</div>
                    <div className="status-prop-value tg-value-text">
                        {calculatePnL(() => tradingState.commonPnL)}
                    </div>
                </div>
                <div className="status-separator tg-hr-separator"/>
                <div className="status-wrapper">
                    <div className="status-prop">
                        <div className="status-prop-name tg-name-text">
                            <div className="status-info-wrapper">Status:</div>
                        </div>
                        <div className="status-prop-value tg-value-text">
                            <div className="status-info-wrapper">
                                <div className="status-info" style={{border: '1px solid ' + statusColor(status)}}>
                                    <div className="status-info-main" style={{color: statusColor(status)}}>
                                        {status}
                                    </div>
                                    <div className="status-info-additional" style={{color: statusColor(status)}}>
                                        {extractAdditionalInfo(status)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CurrentTradingStatus;