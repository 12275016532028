import React, {useState} from 'react';
import axios from 'axios';
import './Settings.css'
import '../Common.css'
import TGKeycloak from "../keycloak/TGKeycloak";
import {toast} from "react-toastify";
import {Tooltip as ReactTooltip} from "react-tooltip";

function BinanceKeys() {
    const [apiKey, setApiKey] = useState('');
    const [apiSecret, setApiSecret] = useState('');

    const handleApiKeyChange = (event) => {
        setApiKey(event.target.value);
    }

    const handleApiSecretChange = (event) => {
        setApiSecret(event.target.value);
    }

    const handleSave = () => {
        axios.post(`${window._env_.REACT_APP_API_HOST}/api/v1/traders/current/credentials`, {
            serviceType: 'BINANCE_FUTURES',
            apiKey: apiKey,
            apiSecret: apiSecret
        }, {
            headers: {'Authorization': 'Bearer ' + TGKeycloak.token},
        })
            .then(value => {
                if (value.status === 200) {
                    toast.info('Binance Credentials updated', {
                        position: 'top-right',
                        autoClose: 3000,
                        className: "notification",
                    })
                }
            });
    }

    return (
        <div className="settings-block">
            <div className="settings-header tg-header-text">Binance Credentials</div>
            <div className="settings-prop">
                <div className="settings-prop-name">
                    <div className="settings-prop-name-text tg-name-text" data-tooltip-id="api-key-tooltip">
                        API Key <span className="setting-prop-name-mark">?</span>
                    </div>
                </div>
                <div className="settings-prop-value">
                    <input className="tg-input" type="password" value={apiKey} onChange={handleApiKeyChange}/>
                </div>
            </div>
            <div className="settings-prop">
                <div className="settings-prop-name tg-name-text">API Secret</div>
                <div className="settings-prop-value">
                    <input className="tg-input" type="password" value={apiSecret} onChange={handleApiSecretChange}/>
                </div>
            </div>
            <div className="settings-buttons-container">
                <button className="settings-button tg-button" onClick={handleSave}>Save</button>
            </div>
            <ReactTooltip
                id="api-key-tooltip"
                place="right-start"
                className="settings-tooltip"
                content="The API key is required only with permission for futures trading."
            />
        </div>
    );
}

export default BinanceKeys;