import React, {useState, useEffect} from 'react';
import axios from 'axios';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import TGKeycloak from "../keycloak/TGKeycloak";
import './Settings.css'
import '../Common.css'
import {useTraderState} from "../trader/TraderStateProvider";
import {toast} from "react-toastify";
import {Tooltip} from "react-tooltip";

function GeneralSettings() {
    const [selectTimezone, setSelectTimezone] = useState('')
    const [terminateTradingDurationId, setTerminateTradingDurationId] = useState('');
    const [timezones, setTimezones] = useState([]);
    const [terminateTradingDurations, setTerminateTradingDurations] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const {traderState, updateTraderState, stateInitialized} = useTraderState();

    useEffect(() => {
        axios.get(`${window._env_.REACT_APP_API_HOST}/api/v1/common/timezones`)
            .then(response => setTimezones(response.data))
            .catch(error => {
                console.error('Error fetching timezones:', error);
            });

        axios.get(`${window._env_.REACT_APP_API_HOST}/api/v1/terminate-trading-durations`, {
            headers: {'Authorization': 'Bearer ' + TGKeycloak.token},
        })
            .then(response => {
                setTerminateTradingDurations(response.data);
            })
            .catch(error => {
                console.error('Error fetching terminate trading durations:', error);
            });
    }, []);

    useEffect(() => {
        setSelectTimezone(traderState.currentTimezone)
    }, [traderState, stateInitialized])

    const handleSave = () => {
        const newState = {
            currentTimezone: selectTimezone,
            terminateTradingDurationId: terminateTradingDurationId,
            notificationsWithSound: traderState.notificationsWithSound
        }
        updateTraderState(newState)
            .then(() => {
                setEditMode(false)
                toast.info("General settings updated", {
                    position: 'top-right',
                    autoClose: 3000,
                    className: "notification",
                })
            })
    };

    const formatDuration = (durationType, value) => {
        if (durationType === 'FIXED') {
            // return moment.duration(value).humanize();
            let isoDuration = moment.duration(value);
            return humanizeDuration(isoDuration.asMilliseconds())
        } else if (durationType === 'END_OF_DAY') {
            return 'Until the end of the day';
        } else {
            return '';
        }
    };

    function extractTerminateTradingDurationId() {
        if (terminateTradingDurationId !== '') {
            return terminateTradingDurationId;
        } else {
            let foundDuration = terminateTradingDurations
                .filter(terminateTradingDuration =>
                    terminateTradingDuration.value === traderState.terminateTradingDuration &&
                    terminateTradingDuration.durationType === traderState.durationType)
                .values()
                .next()
                .value;
            let foundDurationId = foundDuration !== undefined
                ? foundDuration.id
                : terminateTradingDurations
                    .values()
                    .next()
                    .value.id;
            setTerminateTradingDurationId(foundDurationId);
            return foundDurationId;
        }
    }

    function wrap(valueExtractor) {
        if (stateInitialized) {
            return valueExtractor()
        } else {
            return ''
        }
    }

    return (
        <div className='settings-block'>
            <div className='settings-header tg-header-text'>
                General Settings
            </div>
            <div className='settings-prop'>
                <div className='settings-prop-name tg-name-text'>Trader ID</div>
                <div className='settings-prop-value tg-value-text'>{wrap(() => traderState.id)}</div>
            </div>
            <div className='settings-prop'>
                <div className='settings-prop-name tg-name-text'>Current timezone</div>
                <div className='settings-prop-value tg-value-text'>
                    {editMode ? (
                        <select className="tg-input"
                                value={selectTimezone}
                                onChange={(e) => setSelectTimezone(e.target.value)}
                        >
                            {timezones.map(timezone => (
                                <option key={timezone} value={timezone}>{timezone}</option>
                            ))}
                        </select>
                    ) : (
                        // Display the current value in read-only mode
                        <span>{wrap(() => traderState.currentTimezone)}</span>
                    )}
                </div>
            </div>
            <div className='settings-prop'>
                <div className='settings-prop-name'>
                    <div className='settings-prop-name-text tg-name-text' data-tooltip-id='trade-blocking-tooltip'>
                        Duration of trade blocking <span className="setting-prop-name-mark">?</span>
                    </div>
                </div>
                <div className='settings-prop-value tg-value-text'>
                    {editMode ? (
                        // Display a select field in edit mode
                        <select className="tg-input"
                                value={extractTerminateTradingDurationId()}
                                onChange={(e) => setTerminateTradingDurationId(e.target.value)}
                        >
                            {terminateTradingDurations.map(duration => (
                                <option key={duration.id} value={duration.id}>
                                    {formatDuration(duration.durationType, duration.value)}
                                </option>
                            ))}
                        </select>
                    ) : (
                        // Display the current value in read-only mode
                        <span>{wrap(() =>
                            formatDuration(traderState.durationType, traderState.terminateTradingDuration))}</span>
                    )}
                </div>
            </div>
            <Tooltip
                id="trade-blocking-tooltip"
                place="right-start"
                className="settings-tooltip"
                content="How does the lock function work? Due to the limitations of the Binance API, it's impossible
                to block the ability to open trades. Instead, we close all trades you have opened after you reach the
                limit you have set. It's important to note that in this case, losses are possible, as the trader pays a
                commission each time a trade is opened and closed. There is also a possibility of price changes in volatile coins.
                You can choose the lock period: from 6 hours to the end of the day (00:00), and also set your time zone."
            />
            <div className="settings-buttons-container">
                {editMode ? (
                    <div className="settings-button-wrapper">
                        <button className="settings-button tg-button" onClick={handleSave}>Save</button>
                        <button className="settings-button tg-button"
                                onClick={() => {
                                    setEditMode(false)
                                    setSelectTimezone(traderState.currentTimezone)
                                }}>Cancel
                        </button>
                    </div>
                ) : (
                    <button className="settings-button tg-button"
                            onClick={() => setEditMode(true)}>Edit</button>
                )}
            </div>
        </div>
    );
}

export default GeneralSettings;