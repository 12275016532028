import React from 'react';
import GeneralSettings from './GeneralSettings';
import BinanceKeys from "./BinanceKeys";
import '../Common.css'
import NotificationSettings from "./NotificationSettings";

function Settings() {
    return (
        <div className="container">
            <div className="content-container">
                <GeneralSettings />
                <div className="settings-separator tg-hr2-separator" />
                <NotificationSettings />
                <div className="settings-separator tg-hr2-separator" />
                <BinanceKeys />
            </div>
        </div>
    );
}
Settings.canonicalName = 'Settings'

export default Settings;