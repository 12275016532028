import './Main.css'
import '../Common.css'

function About() {
    return (
        <div className="about-us-container">
            <div className="about-us-item">
                <div className="about-us-item-name tg-main-header-text">
                    What It Is
                </div>
                <div className="about-us-item-value tg-main-text">
                    Welcome to TradeGuard.app – your reliable
                    tool for secure crypto trading. Our service
                    offers a unique opportunity to manage risks,
                    set trading limits, and ensure thoughtfulness
                    in each transaction. It's the perfect solution
                    for those aiming for efficiency and safety in
                    cryptocurrency trading.
                </div>
            </div>
            <div className="about-us-item">
                <div className="about-us-item-name tg-main-header-text">
                    How It Works
                </div>
                <div className="about-us-item-value tg-main-text">
                    With TradeGuard.app, you can easily set limits
                    for individual trades and daily trading. Once
                    these limits are reached, the service automatically
                    closes open trades to prevent losses. Additionally,
                    we provide a trading lock feature for a period set by
                    you, helping to avoid impulsive decisions. And with
                    notifications about limit breaches, you're always
                    informed about the state of your trades.
                </div>
            </div>
            <div className="about-us-item">
                <div className="about-us-item-name tg-main-header-text">
                    Who We Are
                </div>
                <div className="about-us-item-value tg-main-text">
                    We are a team passionate about cryptocurrencies and
                    innovations, having developed TradeGuard.app to provide
                    traders with a reliable, secure, and intuitive way to
                    manage risks. Our goal is to ensure maximum control and
                    efficiency in your trading operations, while maintaining
                    high security standards.
                </div>
            </div>
        </div>
    )
}

export default About;