import './NotFound.css'
import '../Common.css'

function NotFound() {
    return (
        <div className='error-page-container'>
            <div className='error-page-content'>
                <h1 className='tg-header-text'>404</h1>
                <h2 className='tg-name-text'>Not Found</h2>
                <p className='tg-value-text'>The page you're looking for doesn't exist</p>
            </div>
        </div>
    );
}

export default NotFound;