import '../Common.css'
import './Wishlist.css'
import {useEffect, useState} from "react";
import axios from "axios";
import TGKeycloak from "../keycloak/TGKeycloak";
import {useNavigate} from "react-router-dom";
import formatDate from "../util/DateUtil"
import VoteElement from "./VoteElement";
import useFetchWishes from "../hook/useFetchWishes";
import useFetchVotes from "../hook/useFetchVotes";
import {useTraderState} from "../trader/TraderStateProvider";

function Wishlist() {

    const navigate = useNavigate()
    const [wishes, setWishes] = useState(new Map())
    const [voteEnabled, setVoteEnabled] = useState(true)
    const [activatedSortOrderId, setActivatedSortOrderId] = useState('sort-creation-date-up')
    const [activatedStatusId, setActivatedStatusId] = useState('wishlist-status-button-opened')
    const {traderState, stateInitialized} = useTraderState()

    const {wishes: loadedWishes, loaded: wishesLoaded, updateStatus, updateSort}
        = useFetchWishes('OPENED', 'creationDate,desc')
    const {votes, setVotes, loaded: votesLoaded} = useFetchVotes()

    useEffect(() => {
        if (wishesLoaded && votesLoaded) {
            const extractedWishes = new Map()
            loadedWishes.forEach(wish => {
                extractedWishes.set(wish.id, wish)
            })
            setWishes(extractedWishes)
        }
    }, [loadedWishes, wishesLoaded, votesLoaded])

    useEffect(() => {
        const activatedSortElem = document.getElementById(activatedSortOrderId)
        activatedSortElem.classList.remove('sort-deactivated')
        activatedSortElem.classList.add('sort-activated')
    }, [activatedSortOrderId])

    useEffect(() => {
        const activatedStatusElem = document.getElementById(activatedStatusId)
        activatedStatusElem.classList.remove('wishlist-status-button-deactivated')
        activatedStatusElem.classList.add('wishlist-status-button-activated')
    }, [activatedStatusId])

    function switchSortOrder(newSortOrder) {
        return function (event) {
            const targetId = event.target.id
            // setSortOrder(newSortOrder)

            updateSort(newSortOrder)
            setActivatedSortOrderId(prevSortId => {
                const prevSortElem = document.getElementById(prevSortId);
                prevSortElem.classList.remove('sort-activated')
                prevSortElem.classList.add('sort-deactivated')
                return targetId
            })
        }
    }

    function switchStatus(status) {
        return event => {
            if (updateStatus(status)) {
                const targetId = event.target.id
                setActivatedStatusId(prevStatusId => {
                    const prevSortElem = document.getElementById(prevStatusId);
                    prevSortElem.classList.remove('wishlist-status-button-activated')
                    prevSortElem.classList.add('wishlist-status-button-deactivated')
                    return targetId
                })
                setVoteEnabled(status === 'OPENED')
            }
        };
    }

    function redirectToFormCreate() {
        navigate('/wishlist/new')
    }


    function redirectToWishItem(id) {
        return function () {
            navigate('/wishlist/items/' + id)
        }
    }

    const updateWish = (updatedWish) => {
        setWishes(curWishes => {
            curWishes.set(updatedWish.id, updatedWish)
            return new Map(curWishes)
        })
    }

    function getVoteElementConfig(wish) {
        return {
            traderId: traderState.id,
            votes: votes,
            votesUpdater: setVotes,
            wishSupplier: () => wishes.get(wish.id),
            wishUpdater: updateWish,
            voteEnabled: voteEnabled
        }
    }

    return (
        <div className="container">
            <div className="content-container">
                <div className="wishlist-header tg-header-text">Wishlist</div>
                <div className="wishlist-container">
                    <div className="wishlist-buttons">
                        <button className="wishlist-create-button tg-button" onClick={redirectToFormCreate}>CREATE
                        </button>
                        <div className="wishlist-status-switcher">
                            <div className="tg-name-text">Displayed wishes:</div>
                            <div className="wishlist-status-buttons">
                                <div id="wishlist-status-button-opened"
                                     className="wishlist-status-button wishlist-status-button-activated wishlist-status-button-left tg-button"
                                     onClick={switchStatus('OPENED')}>
                                    Opened
                                </div>
                                <div id="wishlist-status-button-implemented"
                                     className="wishlist-status-button wishlist-status-button-deactivated wishlist-status-button-right tg-button"
                                     onClick={switchStatus('IMPLEMENTED')}>
                                    Implemented
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wishlist-nav">
                        <div
                            className="wishlist-nav-elem wishlist-item-column wishlist-item-creation-date tg-name-text">
                            Creation date
                            <div className="sort-order-elem">
                                <i id="sort-creation-date-up" className="fa-solid fa-angle-up sort-deactivated"
                                   onClick={switchSortOrder('creationDate,desc')}></i>
                                <i id="sort-creation-date-down" className="fa-solid fa-angle-down sort-deactivated"
                                   onClick={switchSortOrder('creationDate,asc')}></i>
                            </div>
                        </div>
                        <div className="wishlist-item-internal-separator tg-vr2-separator"/>
                        <div className="wishlist-nav-elem wishlist-item-column wishlist-item-name tg-name-text">
                            Name
                            <div className="sort-order-elem">
                                <i id="sort-name-up" className="fa-solid fa-angle-up sort-deactivated"
                                   onClick={switchSortOrder('name,asc')}></i>
                                <i id="sort-name-down" className="fa-solid fa-angle-down sort-deactivated"
                                   onClick={switchSortOrder('name,desc')}></i>
                            </div>
                        </div>
                        <div className="wishlist-item-internal-separator tg-vr2-separator"/>
                        <div className="wishlist-nav-elem wishlist-item-column vote-element-nav tg-name-text">
                            <div className="sort-order-elem">
                                <i id="sort-positives-up" className="fa-solid fa-angle-up sort-deactivated"
                                   onClick={switchSortOrder('positiveVotesNumber,desc')}></i>
                                <i id="sort-positives-down" className="fa-solid fa-angle-down sort-deactivated"
                                   onClick={switchSortOrder('positiveVotesNumber,asc')}></i>
                            </div>
                        </div>
                        <div className="wishlist-nav-elem wishlist-item-column vote-element-nav tg-name-text">
                            <div className="sort-order-elem">
                                <i id="sort-negatives-up" className="fa-solid fa-angle-up sort-deactivated"
                                   onClick={switchSortOrder('negativeVotesNumber,desc')}></i>
                                <i id="sort-negatives-down" className="fa-solid fa-angle-down sort-deactivated"
                                   onClick={switchSortOrder('negativeVotesNumber,asc', {})}></i>
                            </div>
                        </div>
                    </div>
                    {stateInitialized
                        ? [...wishes.values()].map((wish) => (
                            <div id={wish.id} key={wish.id} className="wishlist-item-wrapper">
                                <div className="wishlist-item">
                                    <div className="wishlist-item-interactive" onClick={redirectToWishItem(wish.id)}>
                                        <div
                                            className="wishlist-item-column wishlist-item-creation-date tg-value-text">
                                            {formatDate(wish.creationDate, traderState.currentTimezone)}</div>
                                        <div className="wishlist-item-internal-separator tg-vr2-separator"/>
                                        <div
                                            className="wishlist-item-column wishlist-item-name tg-value-text">{wish.name}</div>
                                    </div>
                                    <div className="wishlist-item-internal-separator tg-vr2-separator"/>
                                    {VoteElement(getVoteElementConfig(wish))}
                                </div>
                                <div className="wishlist-item-separator tg-hr2-separator"/>
                            </div>
                        ))
                        : ''}
                </div>
            </div>
        </div>
    );
}
Wishlist.canonicalName = 'Wishlist'

export default Wishlist;