import React from 'react';
import './Header.css';
import background from '../../data/img/TG.png'
import {useKeycloak} from "@react-keycloak/web";
import {Link} from "react-router-dom";
import GetDataUri from "../../util/StaticData";

function Header() {

    const {keycloak, initialized} = useKeycloak();

    function getLogoutURL() {
        return keycloak.createLogoutUrl({redirectUri: window.location.origin + '/'})
    }

    const getProfileUrl = () => {
        return keycloak.createAccountUrl()
    };

    return (
        <header className="header">
            <div className="header-left">
                <div className="nav-element">
                    <Link to="/home">
                        <img className="header-logo" src={GetDataUri(background)} alt="HOME"/>
                    </Link>
                </div>
                <div className="nav-element">
                    <Link to="/home">HOME</Link>
                </div>
                <div className="nav-element">
                    <Link to="/settings">SETTINGS</Link>
                </div>
                <div className="nav-element">
                    <Link to="/wishlist">WISHLIST</Link>
                </div>
                <div className="nav-element">
                    <Link to="/contacts">CONTACTS</Link>
                </div>
                <div className="nav-element">
                    <Link to="/faq">FAQ</Link>
                </div>
            </div>
            {initialized &&
                <div className="header-right">
                    <div className="nav-element">
                        <a href={getProfileUrl()} rel="noopener noreferrer">PROFILE</a>
                    </div>
                    <div className="nav-element nav-element-bordered">
                        <a href={getLogoutURL()} rel="noopener noreferrer">Logout</a>
                    </div>
                </div>
            }
            {!initialized &&
                <div className="header-right">
                    <div className="nav-element">
                        <a href='/' rel="noopener noreferrer">PROFILE</a>
                    </div>
                    <div className="nav-element nav-element-bordered">
                        <a href='/' rel="noopener noreferrer">Logout</a>
                    </div>
                </div>
            }
        </header>
    );
}

export default Header;