import Header from "./header/Header";
import Background from "./background/Background";
import React, {useCallback, useEffect} from "react";
import {useKeycloak} from "@react-keycloak/web";
import notificationManager from "../notification/NotificationManager";
import {toast, ToastContainer} from "react-toastify";
import '../notification/Notification.css'
import posTerminatedUrl from '../data/sound/position_terminated.wav'
import tradingBlockedUrl from '../data/sound/trading_blocked.wav'
import tradingUnblockedUrl from '../data/sound/trading_unblocked.wav'
import {useTraderState} from "../trader/TraderStateProvider";
import GetDataUri from "../util/StaticData";

function ClientContentContainer() {

    const {initialized, keycloak} = useKeycloak();
    const {traderState} = useTraderState()
    const {notificationsWithSound} = traderState

    useEffect(() => {
        if (initialized) {
            notificationManager.close()
            notificationManager.startListening(keycloak.token)
        }
    }, [initialized, keycloak.token])

    const handleNotification = useCallback((message, sound) => {
        toast.info(message, {
            position: 'top-right',
            autoClose: 10000,
            className: "notification",
        })
        if (notificationsWithSound === true) {
            sound.play()
                .catch(error => console.error("Error playing sound:", error));
        }
    }, [notificationsWithSound])

    useEffect(() => {
        const posTerminatedSound = new Audio(GetDataUri(posTerminatedUrl));
        const tradingBlockedSound = new Audio(GetDataUri(tradingBlockedUrl));
        const tradingUnblockedSound = new Audio(GetDataUri(tradingUnblockedUrl));

        notificationManager.registerHandler('TRADING_BLOCKED',
            () => handleNotification('Trading is blocked !', tradingBlockedSound))
        notificationManager.registerHandler('TRADING_UNBLOCKED',
            () => handleNotification('Trading is unblocked !', tradingUnblockedSound))
        notificationManager.registerHandler('POSITION_TERMINATED',
            event => handleNotification('Position for ' + event.symbol + ' is terminated !', posTerminatedSound))

    }, [handleNotification])

    useEffect(() => {
        return () => {
            notificationManager.unregisterHandler('TRADING_BLOCKED')
            notificationManager.unregisterHandler('TRADING_UNBLOCKED')
            notificationManager.unregisterHandler('POSITION_TERMINATED')
            notificationManager.close()
        }
    }, [])

    return (
        <div>
            <Header/>
            <Background/>
            <ToastContainer/>
        </div>
    )
}

export default ClientContentContainer