import React from 'react';
import './Header.css';
import './MainHeader.css';
import background from '../../data/img/TG.png'
import {useKeycloak} from "@react-keycloak/web";
import {Link} from "react-router-dom";
import GetDataUri from "../../util/StaticData";

function MainHeader() {

    const {keycloak, initialized} = useKeycloak();

    function getRegisterURL() {
        return initialized
            ? keycloak.createRegisterUrl({redirectUri: window.location.origin + '/home'})
            : ""
    }

    function getLoginURL() {
        return initialized
            ? keycloak.createLoginUrl({redirectUri: window.location.origin + '/home'})
            : ""
    }

    return (
        <header className="main-header header">
            <div className="header-left">
                <div className="nav-element">
                    <Link to="/">
                        <img className="header-logo" src={GetDataUri(background)} alt="HOME"/>
                    </Link>
                </div>
            </div>
            <div className="header-right">
                <div className="nav-element">
                    <a href={getRegisterURL()} rel="noopener noreferrer">SIGN UP</a>
                </div>
                <div className="nav-element nav-element-bordered">
                    <a href={getLoginURL()} rel="noopener noreferrer">Login</a>
                </div>
            </div>
        </header>
    );
}

export default MainHeader;