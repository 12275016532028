import React from "react";
import {useTraderState} from "../trader/TraderStateProvider";
import {toast} from "react-toastify";
import '../notification/Notification.css'

function NotificationSettings() {

    const {traderState, updateTraderState, stateInitialized} = useTraderState()

    function getChecked() {
        if (stateInitialized === true) {
            return traderState.notificationsWithSound;
        } else {
            return false
        }
    }

    function switchNotificationsWithSound() {
        const newState = {
            currentTimezone: traderState.currentTimezone,
            terminateTradingDurationId: traderState.terminateTradingDurationId,
            notificationsWithSound: !traderState.notificationsWithSound
        }
        updateTraderState(newState)
            .then(() => {
                toast.info("Notification settings updated", {
                    position: 'top-right',
                    autoClose: 3000,
                    className: "notification",
                })
            })
    }

    return (
        <div className='settings-block'>
            <div className='settings-header tg-header-text'>
                Notification Settings
            </div>
            <div className='settings-prop'>
                <div className='settings-prop-name tg-name-text'>Sound</div>
                <div className='settings-prop-value'>
                    <label className="tg-checkbox-container">
                        <input
                            type="checkbox"
                            checked={getChecked()}
                            onChange={() => switchNotificationsWithSound()}
                            contentEditable='false'
                        />
                        <span className="tg-checkbox-mark"></span>
                    </label>
                </div>
            </div>
        </div>
    )
}

export default NotificationSettings