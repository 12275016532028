import {createContext, useContext, useEffect, useState} from "react";

const ConditionalContentContext = createContext({
    showConditionalContent: true,
    updateShowConditionalContent: (_) => {
    }
})

export const useConditionalContentSwitcher = () => useContext(ConditionalContentContext)

export const ConditionalContentSwitcher = ({children}) => {
    const [showContent, setShowContent] = useState(true);

    return <ConditionalContentContext.Provider value={{
        showConditionalContent: showContent, updateShowConditionalContent: value => setShowContent(value)
    }}>
        {children}
    </ConditionalContentContext.Provider>
}

export const NoConditionalContent = ({children}) => {
    const {updateShowConditionalContent} = useConditionalContentSwitcher();

    useEffect(() => {
        updateShowConditionalContent(false)
        return () => updateShowConditionalContent(true)
    }, [updateShowConditionalContent])

    return <>{children}</>
}