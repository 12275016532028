import MainBackground from "./background/MainBackground";
import MainHeader from "./header/MainHeader";
import React from "react";

function MainPageContentContainer() {
    return (
        <div>
            <MainBackground/>
            <MainHeader/>
        </div>
    )
}

export default MainPageContentContainer
