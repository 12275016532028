import Keycloak from 'keycloak-js';

const isBrowser = typeof window !== "undefined";

const keycloakConfig = {
    url: isBrowser
        ? window._env_.REACT_APP_AUTH_SERVER_HOST
        : process.env.REACT_APP_AUTH_SERVER_HOST,
    realm: isBrowser
        ? window._env_.REACT_APP_AUTH_SERVER_REALM
        : process.env.REACT_APP_AUTH_SERVER_REALM,
    clientId: isBrowser
        ? window._env_.REACT_APP_AUTH_CLIENT_ID
        : process.env.REACT_APP_AUTH_CLIENT_ID,
};

const TGKeycloak = new Keycloak(keycloakConfig);

export default TGKeycloak;
