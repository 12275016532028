import {createContext, useCallback, useContext, useEffect, useState} from "react";
import axios from "axios";
import TGKeycloak from "../keycloak/TGKeycloak";
import {useKeycloak} from "@react-keycloak/web";

const TraderStateContext = createContext({
    traderState: {},
    updateTraderState: ({}) => {
    },
    stateInitialized: false
})

export const TraderStateProvider = ({children}) => {
    const [traderState, setTraderState] = useState({});
    const [stateInitialized, setStateInitialized] = useState(false);
    const [terminateTradingDurations, setTerminateTradingDurations] = useState([]);
    const {initialized, keycloak} = useKeycloak();

    function extractTerminateTradingDurationId(state, durations) {
        let foundDuration = durations
            .filter(terminateTradingDuration =>
                terminateTradingDuration.value === state.terminateTradingDuration &&
                terminateTradingDuration.durationType === state.durationType)
            .values()
            .next()
            .value;
        return foundDuration !== undefined
            ? foundDuration.id
            : durations
                .values()
                .next()
                .value.id;
    }

    const setupNewTraderState = useCallback((state, durations) => {
        const newState = {
            ...state,
            terminateTradingDurationId: extractTerminateTradingDurationId(state, durations)
        }
        setTraderState(newState);
        console.log(newState);
    }, [])

    const extractTraderState = useCallback(durations => {
        axios.get(`${window._env_.REACT_APP_API_HOST}/api/v1/traders/current`, {
            headers: {'Authorization': 'Bearer ' + TGKeycloak.token},
        })
            .then(response => {
                setupNewTraderState(response.data, durations)
                setStateInitialized(true)
            })
            .catch(error => {
                console.error(error);
            });
    }, [setupNewTraderState])

    useEffect(() => {
        if (initialized && keycloak.authenticated) {
            axios.get(`${window._env_.REACT_APP_API_HOST}/api/v1/terminate-trading-durations`, {
                headers: {'Authorization': 'Bearer ' + TGKeycloak.token},
            })
                .then(response => {
                    const durations = response.data
                    setTerminateTradingDurations(durations);
                    extractTraderState(durations)
                })
                .catch(error => {
                    console.error('Error fetching terminate trading durations:', error);
                });
        }
    }, [extractTraderState, initialized, keycloak.authenticated])

    const updateTraderState = (newState) => {
        return axios.put(
            `${window._env_.REACT_APP_API_HOST}/api/v1/traders/current`,
            newState, {
                headers: {'Authorization': 'Bearer ' + TGKeycloak.token},
            }
        ).then(response => {
            setupNewTraderState(response.data, terminateTradingDurations)
            console.log('Trader state updated')
        }).catch(error => {
            console.error('Error updating trader settings:', error);
        });
    }

    return (
        <TraderStateContext.Provider value={{
            traderState: traderState,
            updateTraderState: updateTraderState,
            stateInitialized: stateInitialized
        }}>
            {children}
        </TraderStateContext.Provider>
    )
}

export const useTraderState = () => useContext(TraderStateContext)