import CurrentTradingStatus from './CurrentTradingStatus'
import StartStopGuarding from './StartStopGuarding'
import './Home.css'
import '../Common.css'
import axios from "axios";
import TGKeycloak from "../keycloak/TGKeycloak";
import {useEffect, useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import notificationManager from "../notification/NotificationManager";

function Home() {

    const [tradingState, setTradingState] = useState({})
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        notificationManager.registerHandler('GUARDING_STATE_UPDATE', guardingStateUpdateEvent)
        notificationManager.registerHandler('PNL_UPDATE', pnlUpdateEvent)

        axios.get(`${window._env_.REACT_APP_API_HOST}/api/v1/traders/current/guarding/state`, {
            headers: {'Authorization': 'Bearer ' + TGKeycloak.token},
        })
            .then(response => {
                setTradingState(response.data);
                setLoaded(true);
                console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });

        return () => {
            notificationManager.unregisterHandler('GUARDING_STATE_UPDATE')
            notificationManager.unregisterHandler('PNL_UPDATE')
        }
    }, []);

    function guardingStateUpdateEvent(data) {
        setTradingState(data)
    }

    function pnlUpdateEvent(data) {
        setTradingState((prevState) => ({
            ...prevState,
            ...data
        }))
    }

    return (
        <div className="container">
            <div className="status-container">
                <CurrentTradingStatus tradingState={tradingState} stateLoaded={loaded}/>
                <div className="status-element-separator tg-vr2-separator"/>
                <StartStopGuarding/>
            </div>
        </div>
    );
}
Home.canonicalName = 'Home'

export default Home;