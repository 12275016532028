import './Main.css'
import '../Common.css'
import {useKeycloak} from "@react-keycloak/web";
import {useEffect} from "react";
import TGKeycloak from "../keycloak/TGKeycloak";
import About from "./About";
import Feature from "./Feature";
import ContactUs from "./ContactUs";

function Main() {
    const {initialized} = useKeycloak()

    useEffect(() => {
        if (TGKeycloak.authenticated) {
            window.location.pathname = '/home'
        }
    }, [initialized])

    return (
        <div className="container">
            <div className="welcome-page-container">
                <div className="welcome-container">
                    <div className="welcome-label tg-main-text">Welcome to</div>
                    <div className="tg-name-label tg-main-header-text">TRADE GUARD</div>
                </div>
                <About/>
                <Feature/>
                <ContactUs/>
            </div>
        </div>
    )
}
Main.canonicalName = 'Main'

export default Main;