import {useKeycloak} from "@react-keycloak/web";
import axios from "axios";
import TGKeycloak from "../keycloak/TGKeycloak";
import {useCallback, useEffect, useState} from "react";
import {toast} from "react-toastify";
import '../notification/Notification.css'

const ProtectedRoute = ({children}) => {

    const {keycloak, initialized} = useKeycloak();
    const [created, setCreated] = useState(false)

    const handleAccessTokenExpiration = useCallback((error) => {
        if (error.config) {
            return TGKeycloak.updateToken(30)
                .then(() => {
                    // Update the Authorization header
                    error.config.headers['Authorization'] = 'Bearer ' + TGKeycloak.token;
                    // Retry the original request
                    return axios.request(error.config);
                })
                .catch(error => {
                    console.error('Failed to refresh token', error);
                    TGKeycloak.login({redirectUri: window.location.href})
                });
        }
        return Promise.reject(error);
    }, [])

    const handleBadRequest = useCallback((error) => {
        const errors = error.response.data.errors
        if (errors && errors.length > 0) {
            errors.forEach((err) => {
                toast.error(err.defaultMessage, {
                    position: 'top-right',
                    autoClose: 10000,
                    className: "notification",
                })
            })
        }
        return Promise.reject(error)
    }, []);

    const handleError = useCallback((error) => {
        if (error.response.data.message) {
            toast.error(error.response.data.message, {
                position: 'top-right',
                autoClose: 10000,
                className: "notification",
            })
        }
        return Promise.reject(error)
    }, []);

    const handleAPIErrors = useCallback(() => {
        axios.interceptors.response.use(undefined, error => {
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        return handleAccessTokenExpiration(error)
                    case 400:
                        return handleBadRequest(error)
                    default:
                        return handleError(error)
                }
            }
        })
    }, [handleAccessTokenExpiration, handleBadRequest, handleError])

    useEffect(() => {
        if (initialized) {
            handleAPIErrors()
            axios.get(`${window._env_.REACT_APP_API_HOST}/api/v1/traders/current/existence`, {
                headers: {'Authorization': 'Bearer ' + TGKeycloak.token},
            })
                .then(response => {
                    if (response.data === false) {
                        axios.get(`${window._env_.REACT_APP_API_HOST}/api/v1/terminate-trading-durations`, {
                            headers: {'Authorization': 'Bearer ' + TGKeycloak.token},
                        })
                            .then(response => {
                                let durations = response.data
                                let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                                axios.post(`${window._env_.REACT_APP_API_HOST}/api/v1/traders`, {
                                    currentTimezone: timeZone,
                                    terminateTradingDurationId: durations[0].id
                                }, {
                                    headers: {'Authorization': 'Bearer ' + TGKeycloak.token},
                                })
                                    .then(() => {
                                        setCreated(true)
                                    })
                            })
                    } else {
                        setCreated(true)
                    }
                })
        }
    }, [handleAPIErrors, initialized])

    function getLoading() {
        keycloak.login()
        return <div>Loading...</div>
    }

    return (initialized && created) && (keycloak.authenticated ? children : getLoading());
};

export default ProtectedRoute;