import React, {useState} from 'react';
import axios from 'axios';
import TGKeycloak from "../keycloak/TGKeycloak";
import './Home.css'

function StartStopGuarding() {
    const [dayLimitValue, setDayLimitValue] = useState('');
    const [positionLimitValue, setPositionLimitValue] = useState('');
    const [withCommission, setWithCommission] = useState(false);
    const [activeButton, setActiveButton] = useState(true);

    const handleStartClick = () => {
        setActiveButton(false)

        const requestBody = {
            dayLimitValue: dayLimitValue !== '' ? parseFloat(dayLimitValue) : undefined,
            positionLimitValue:
                positionLimitValue !== '' ? parseFloat(positionLimitValue) : undefined,
            withCommission,
        };

        axios.put(`${window._env_.REACT_APP_API_HOST}/api/v1/traders/current/guarding/start`,
            requestBody,
            {headers: {'Authorization': 'Bearer ' + TGKeycloak.token}}
        )
            .then(() => {
                setActiveButton(true)
            })
            .catch(error => {
                console.error('Failed to start guarding:', error)
                setActiveButton(true)
            });
    };

    const handleStopClick = () => {
        setActiveButton(false)
        axios.put(`${window._env_.REACT_APP_API_HOST}/api/v1/traders/current/guarding/stop`,
            {},
            {headers: {'Authorization': 'Bearer ' + TGKeycloak.token}}
        )
            .then(() => {
                setActiveButton(true)
            })
            .catch(error => {
                console.error('Failed to stop guarding:', error)
                setActiveButton(true)
            });
    };

    return (
        <div className="status-element">
            <div className="status-header tg-header-text">Start / Stop Guarding</div>
            <div className="status-prop-container tg-name-text">
                <div className="status-prop">
                    <div className="status-prop-name">With day limit (USDT):</div>
                    <div className="status-prop-value">
                        <input
                            type="number"
                            id="dayLimit"
                            className="status-input tg-input"
                            value={dayLimitValue}
                            onChange={(e) => setDayLimitValue(e.target.value)}
                        />
                    </div>
                </div>
                <div className="status-separator tg-hr-separator"/>
                <div className="status-prop">
                    <div className="status-prop-name">With position limit (USDT):</div>
                    <div className="status-prop-value">
                        <input
                            type="number"
                            className="status-input tg-input"
                            id="positionLimit"
                            value={positionLimitValue}
                            onChange={(e) => setPositionLimitValue(e.target.value)}
                        />
                    </div>
                </div>
                <div className="status-separator tg-hr-separator"/>
                <div className="status-prop">
                    <div className="status-prop-name">Handle fees:</div>
                    <div className="status-prop-value">
                        <label className="tg-checkbox-container">
                            <input
                                type="checkbox"
                                checked={withCommission}
                                onChange={() => setWithCommission((prev) => !prev)}
                                contentEditable='false'
                            />
                            <span className="tg-checkbox-mark"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div className="status-buttons">
                <button className="status-button tg-button" disabled={!activeButton} onClick={handleStartClick}>Start
                </button>
                <button className="status-button tg-button" disabled={!activeButton} onClick={handleStopClick}>Stop
                </button>
            </div>
        </div>
    );
}

export default StartStopGuarding;