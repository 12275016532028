import {useConditionalContentSwitcher} from "./ConditionalContentSwitcher";
import ClientContentContainer from "./ClientContentContainer";
import MainPageContentContainer from "./MainPageContentContainer";

export const ConditionalContent = () => {
    const {showConditionalContent} = useConditionalContentSwitcher()

    return (
        showConditionalContent && (window.location.pathname !== '/'
                ? <ClientContentContainer/>
                : <MainPageContentContainer/>
        )
    )
}