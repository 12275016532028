import '../Common.css'
import './Faq.css'

function Faq() {
    return (
        <div className="container">
            <div className="content-container">
                <div className="faq-header tg-header-text">FAQ</div>
                <div className="tg-name-text">1. How often does TradeGuard update my trading positions and PnL ?</div>
                <div className="faq-value-block">
                    <div className="faq-value-text tg-value-text">
                        Currently, TradeGuard is integrated with the Binance exchange.
                        Our system updates the profit and loss (PnL) information for your trades every second
                        due to the technical limitations of the Binance API. This frequent updating allows
                        for real-time trade management and ensures the automatic closure of trades at the set limits.
                    </div>
                </div>
                <div className="tg-name-text">2. Why do I need to provide a unique Binance API key for TradeGuard ?</div>
                <div className="faq-value-block">
                    <div className="faq-value-text tg-value-text">For TradeGuard to perform its functions optimally — like automatically
                        closing trades and enforcing trading limits — it requires access to your Binance API keys.
                        Our tests indicate that using the same API keys with other services can lead to operational errors.
                        To ensure the smooth and correct functioning of our service, we advise that you generate and use a
                        separate API key exclusively for TradeGuard.
                    </div>
                </div>
                <div className="tg-name-text">3. Setting Up Two-Factor Authentication</div>
                <div className="faq-value-block">
                    <div className="faq-value-text tg-value-text">For enhanced security, enabling two-factor authentication (2FA) is essential.
                        The current navigation to 2FA is a temporary measure and will be simplified in future updates. To activate
                        2FA, please follow these steps: Go to Profile > Personal Info > Account Security > Sign in > Set up
                        Authenticator Application.
                    </div>
                </div>
                <div className="tg-name-text">4. Understanding TradeGuard's Trade Lock Feature</div>
                <div className="faq-value-block">
                    <div className="faq-value-text tg-value-text">Due to Binance's technical constraints, it is not possible to block the initiation
                        of new trades. Instead, TradeGuard has implemented a protective feature: once your specified daily loss limit
                        is reached, any new trade opened on your futures account will be immediately closed by our system.
                        This measure is to ensure that your trading halts at the predetermined risk level set by you.
                        Please be aware that this will result in the standard commission fee by Binance for the closure of the trade.
                        It's an important factor to consider when setting your daily limits and trading strategy.
                    </div>
                </div>
            </div>
        </div>
    )
}
Faq.canonicalName = 'Faq'

export default Faq