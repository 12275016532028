import {Navigate, Route, Routes} from "react-router-dom";
import Main from "../main/Main";
import ProtectedRoute from "../util/ProtectedRoute";
import Home from "../home/Home";
import Settings from "../settings/Settings";
import Wishlist from "../wishlist/Wishlist";
import NewWishlist from "../wishlist/NewWishlist";
import WishlistItem from "../wishlist/WishlistItem";
import Contacts from "../contacts/Contacts";
import Faq from "../faq/Faq";
import {NoConditionalContent} from "../content/ConditionalContentSwitcher";
import NotFound from "../error/NotFound";

const routes = require('../routes/TGRoutesDefinition')

function unprotectedRoute(component) {
    const componentName = component.type.canonicalName
    const routeDefinition = routes.get(componentName)
    if (!routeDefinition) {
        console.log("ERROR loading unprotected " + componentName + " route")
    }
    return <Route exact path={routeDefinition.path} element={component}/>;
}

function protectedRoute(component) {
    const componentName = component.type.canonicalName
    const routeDefinition = routes.get(componentName)
    if (routeDefinition === undefined) {
        console.log("ERROR loading protected " + componentName + " route")
    }
    return <Route path={routeDefinition.path} element={
        <ProtectedRoute>
            {component}
        </ProtectedRoute>
    }/>;
}

export default function TGRoutes() {
    return <Routes>
        {unprotectedRoute(<Main/>)}
        {protectedRoute(<Home/>)}
        {protectedRoute(<Settings/>)}
        {protectedRoute(<Wishlist/>)}
        {protectedRoute(<NewWishlist/>)}
        {protectedRoute(<WishlistItem/>)}
        {protectedRoute(<Contacts/>)}
        {protectedRoute(<Faq/>)}
        <Route path="/404" element={
            <NoConditionalContent>
                <NotFound/>
            </NoConditionalContent>
        }/>
        <Route path="*" element={
            <Navigate to="/404"/>
        }/>
    </Routes>
}