import {BrowserRouter} from 'react-router-dom';
import TGKeycloak from './keycloak/TGKeycloak';
import "./App.css"
import {ReactKeycloakProvider} from "@react-keycloak/web";
import {TraderStateProvider} from "./trader/TraderStateProvider";
import {
    ConditionalContentSwitcher
} from "./content/ConditionalContentSwitcher";
import {ConditionalContent} from "./content/ConditionalContent";
import TGRoutes from "./routes/TGRoutes";

function App() {

    return (
        <ReactKeycloakProvider authClient={TGKeycloak}>
            <TraderStateProvider>
                <BrowserRouter>
                    <ConditionalContentSwitcher>
                        <ConditionalContent/>
                        <TGRoutes/>
                    </ConditionalContentSwitcher>
                </BrowserRouter>
            </TraderStateProvider>
        </ReactKeycloakProvider>
    );
}

export default App;