import '../Common.css'
import './Contacts.css'
import {useEffect, useState} from "react";

function Contacts() {
    const [telegramLink, setTelegramLink] = useState('')
    // const [youtubeLink, setYoutubeLink] = useState('')
    const [email, setEmail] = useState('')

    useEffect(() => {
        setTelegramLink(`${window._env_.REACT_APP_TELEGRAM_LINK}`)
        // setYoutubeLink(`${window._env_.REACT_APP_YOUTUBE_LINK}`)
        setEmail(`${window._env_.REACT_APP_EMAIL}`)
    }, [])

    return (
        <div className="container">
            <div className="content-container">
                <div className="contacts-header tg-header-text">Contact us</div>
                <div className="contacts-block">
                    <div className="contacts-prop">
                        <div className="contacts-prop-logo tg-telegram-logo-wrapper">
                            <div className="tg-telegram-logo-wrapper">
                                <i className="fa-2x fa-brands fa-telegram tg-telegram"></i>
                                <div className="tg-telegram-contacts-background tg-telegram-background"/>
                            </div>
                        </div>
                        <div className="contacts-prop-value tg-name-text">
                            <a href={telegramLink} target="_blank" rel="noreferrer">{telegramLink}</a>
                        </div>
                    </div>
                    {/*<div className="contacts-prop">
                        <div className="contacts-prop-logo tg-youtube-logo-wrapper">
                            <div className="tg-youtube-logo-wrapper">
                                <i className="fa-2x fa-brands fa-youtube tg-youtube"></i>
                                <div className="tg-youtube-background"/>
                            </div>
                        </div>
                        <div className="contacts-prop-value tg-name-text">
                            <a href={youtubeLink} target="_blank" rel="noreferrer">{youtubeLink}</a>
                        </div>
                    </div>*/}
                    <div className="contacts-prop">
                        <div className="contacts-prop-logo tg-email-wrapper">
                            <i className="fa-2x fa-solid fa-envelope tg-email"></i>
                        </div>
                        <div className="contacts-prop-value tg-name-text">{email}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
Contacts.canonicalName = 'Contacts'

export default Contacts;