import '../Common.css'
import './WishlistItem.css'
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import TGKeycloak from "../keycloak/TGKeycloak";
import formatDate from "../util/DateUtil";
import VoteElement from "./VoteElement";
import useFetchVotes from "../hook/useFetchVotes";
import {useTraderState} from "../trader/TraderStateProvider";

function WishlistItem() {

    const [wish, setWish] = useState({})
    const {wishId} = useParams()
    const {votes, setVotes, loaded: votesLoaded} = useFetchVotes([wishId])
    const {traderState, stateInitialized} = useTraderState()
    const navigate = useNavigate()

    useEffect(() => {
        if (votesLoaded) {
            axios.get(`${window._env_.REACT_APP_API_HOST}/api/v1/wishlist-items/` + wishId, {
                headers: {'Authorization': 'Bearer ' + TGKeycloak.token},
            })
                .then(response => {
                    setWish(response.data)
                })
        }
    }, [wishId, votesLoaded])

    function getVoteElementConfig() {
        return {
            traderId: traderState.id,
            votes: votes,
            votesUpdater: setVotes,
            wishSupplier: () => wish,
            wishUpdater: setWish,
            voteEnabled: wish.status === 'OPENED'
        }
    }

    function redirectToList() {
        navigate('/wishlist')
    }

    return (
        <div className="container">
            <div className="wishlist-item-container-wrapper content-container">
                <div className="wishlist-item-header tg-header-text">Wishlist Item</div>
                <i className="tg-back-button fa-solid fa-rotate-left" onClick={redirectToList}/>
                <div className="wishlist-item-container">
                    <div className="wishlist-item-general-info">
                        <div className="wishlist-item-prop">
                            <div className="wishlist-item-prop-name tg-name-text">Creation date:</div>
                            <div className="tg-value-text">
                                {stateInitialized && wish.creationDate !== undefined
                                    ? formatDate(wish.creationDate, traderState.currentTimezone)
                                    : null}
                            </div>
                        </div>
                        <div className="wishlist-item-prop">
                            <div className="wishlist-item-prop-name tg-name-text">Status:</div>
                            <div className="tg-value-text">{wish.status}</div>
                        </div>
                    </div>
                    <div className="wishlist-item-info-separator tg-hr2-separator"/>
                    <div className="wishlist-item-prop">
                        <div className="wishlist-item-prop-name tg-name-text">Name:</div>
                        <div className="tg-value-text">{wish.name}</div>
                    </div>
                    <div className="wishlist-item-info-separator tg-hr2-separator"/>
                    <div className="wishlist-item-prop-column">
                        <div className="wishlist-item-prop-column-name tg-name-text">Description:</div>
                        <div className="tg-value-text">{wish.description}</div>
                    </div>
                    <div className="wishlist-item-vote-container">
                        {stateInitialized && wish.id !== undefined
                            ? VoteElement(getVoteElementConfig())
                            : ''}
                    </div>
                </div>
            </div>
        </div>
    )
}
WishlistItem.canonicalName = 'WishlistItem'

export default WishlistItem;