class NotificationManager {

    constructor() {
        this.handlers = new Map()
    }

    get handlers() {
        return this._handlers;
    }

    set handlers(value) {
        this._handlers = value;
    }

    setEventSource(eSource) {
        this.eventSource = eSource
    }

    getEventSource() {
        return this.eventSource
    }

    setReconnectTimeout(reconnectTimeout) {
        this.reconnectTimout = reconnectTimeout
    }

    getReconnectTimeout() {
        return this.reconnectTimout
    }

    startListening(token) {
        this.listenForNotifications(token);
    }

    listenForNotifications(token) {
        let reconnectDelay = 1000;
        const maxReconnectDelay = 30000;

        const startSSEConnection = () => {
            let eSource = new EventSource(`${window._env_.REACT_APP_NOTIFIER_HOST}/api/v1/notifications?access_token=`
                + token);
            eSource.onmessage = event => this.handleSSEUpdate(this, event);
            eSource.onerror = (error) => {
                console.error("SSE Error:", error);
                this.getEventSource().close();

                // Exponential backoff logic
                this.setReconnectTimeout(
                    setTimeout(() => {
                        reconnectDelay *= 2;
                        if (reconnectDelay > maxReconnectDelay) {
                            reconnectDelay = maxReconnectDelay;
                        }
                        startSSEConnection(); // Re-establish the connection
                    }, reconnectDelay)
                );
            };
            this.setEventSource(eSource)
        }

        startSSEConnection();

        console.log("SSE connection initialized")
    }

    handleSSEUpdate(notifyManager, event) {
        let data = JSON.parse(event.data);
        let content = JSON.parse(data.data);
        const handler = notifyManager.handlers.get(data.type);
        if (handler) {
            handler(content)
        }
    }

    close() {
        if (this.getEventSource()) {
            this.getEventSource().close();
        }
        clearTimeout(this.getReconnectTimeout());
    }

    registerHandler(type, handler) {
        this.handlers.set(type, handler);
    }

    unregisterHandler(type) {
        this.handlers.delete(type);
    }
}

// Create a singleton instance
const notificationManager = new NotificationManager();
export default notificationManager;
