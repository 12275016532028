import './Main.css'
import '../Common.css'
import {useEffect, useState} from "react";

function ContactUs() {

    const [telegramLink, setTelegramLink] = useState('')
    // const [youtubeLink, setYoutubeLink] = useState('')
    const [email, setEmail] = useState('')

    useEffect(() => {
        setTelegramLink(`${window._env_.REACT_APP_TELEGRAM_LINK}`)
        // setYoutubeLink(`${window._env_.REACT_APP_YOUTUBE_LINK}`)
        setEmail(`${window._env_.REACT_APP_EMAIL}`)
    }, [])

    function handleRedirect(link) {
        return () => window.open(link, '_blank')
    }

    return (
        <div className="follow-us-container">
            <div className="follow-us-header tg-main-header-text">CONTACT US</div>
            <div className="follow-us-content-container">
                <div className="follow-us-item-wrapper">
                    <div className="follow-us-item follow-us-item-interactive" onClick={handleRedirect(telegramLink)}>
                        <div className="tg-telegram-logo-wrapper">
                            <div className="tg-telegram-logo-wrapper">
                                <i className="fa-3x fa-brands fa-telegram tg-telegram"></i>
                                <div className="tg-telegram-main-background tg-telegram-background"/>
                            </div>
                        </div>
                        <div className="follow-us-link tg-main-text">Telegram</div>
                    </div>
                </div>
                {/*<div className="follow-us-item-wrapper">
                    <div className="follow-us-item follow-us-item-interactive" onClick={handleRedirect(youtubeLink)}>
                        <div className="tg-youtube-logo-wrapper">
                            <div className="tg-youtube-logo-wrapper">
                                <i className="fa-3x fa-brands fa-youtube tg-youtube"></i>
                                <div className="tg-youtube-main-background tg-youtube-background"/>
                            </div>
                        </div>
                        <div className="follow-us-link tg-main-text">YouTube</div>
                    </div>
                </div>*/}
                <div className="follow-us-item-wrapper">
                    <div className="follow-us-item">
                        <div className="tg-email-wrapper">
                            <i className="fa-3x fa-solid fa-envelope tg-email"></i>
                        </div>
                        <div className="follow-us-link tg-main-text">{email}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs