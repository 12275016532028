import axios from "axios";
import TGKeycloak from "../keycloak/TGKeycloak";
import "./Vote.css"

function VoteElement(voteElementConfig) {

    const votes = extractVotes(voteElementConfig.votes)

    function extractVotes(votes) {
        const extractedVotes = new Map()
        votes.forEach(vote => {
            extractedVotes.set(vote.wishlistItemId, vote)
        })
        return extractedVotes
    }

    function isVoted(wish, voteValue) {
        return votes.has(wish.id)
            && votes.get(wish.id).value === voteValue
    }

    function getWishVoteItemId(wish, voteValue) {
        if (voteValue === 'POSITIVE') {
            return 'wish-' + wish.id + "-positive-vote"
        } else {
            return 'wish-' + wish.id + "-negative-vote"
        }
    }

    function handleVoteClick(config, voteValue) {
        if (config.voteEnabled) {
            return function () {
                const wish = config.wishSupplier()
                if (!votes.has(wish.id)) {
                    axios.put(`${window._env_.REACT_APP_API_HOST}/api/v1/votes`, {
                        wishlistItemId: wish.id,
                        value: voteValue
                    }, {
                        headers: {'Authorization': 'Bearer ' + TGKeycloak.token},
                    })
                        .then(response => {
                            activateItem(wish, response.data)
                        })
                } else if (votes.get(wish.id).value === voteValue) {
                    const vote = votes.get(wish.id)
                    axios.delete(`${window._env_.REACT_APP_API_HOST}/api/v1/votes/` + vote.id, {
                        headers: {'Authorization': 'Bearer ' + TGKeycloak.token},
                    })
                        .then(() => {
                            deactivateItem(wish, voteValue)
                        })
                } else {
                    axios.put(`${window._env_.REACT_APP_API_HOST}/api/v1/votes`, {
                        wishlistItemId: wish.id,
                        value: voteValue
                    }, {
                        headers: {'Authorization': 'Bearer ' + TGKeycloak.token},
                    })
                        .then(response => {
                            switchItem(wish, response.data)
                        })
                }
            }
        } else {
            return () => {}
        }
    }

    function deactivateItem(wish, voteValue) {
        votes.delete(wish.id)
        if (voteValue === 'POSITIVE') {
            wish.positiveVotesNumber = wish.positiveVotesNumber - 1
        } else {
            wish.negativeVotesNumber = wish.negativeVotesNumber - 1
        }
        voteElementConfig.votesUpdater([...votes.values()])
        voteElementConfig.wishUpdater(wish)
    }

    function activateItem(wish, voteResponse) {
        votes.set(wish.id, {
            id: voteResponse.id,
            value: voteResponse.value,
            wishlistItemId: wish.id,
            voterId: voteElementConfig.traderId
        })
        if (voteResponse.value === 'POSITIVE') {
            wish.positiveVotesNumber = wish.positiveVotesNumber + 1
        } else {
            wish.negativeVotesNumber = wish.negativeVotesNumber + 1
        }
        voteElementConfig.votesUpdater([...votes.values()])
        voteElementConfig.wishUpdater(wish)
    }

    function switchItem(wish, voteResponse) {
        votes.set(wish.id, {
            id: voteResponse.id,
            value: voteResponse.value,
            wishlistItemId: wish.id,
            voterId: voteElementConfig.traderId
        })
        if (voteResponse.value === 'POSITIVE') {
            wish.positiveVotesNumber = wish.positiveVotesNumber + 1
            wish.negativeVotesNumber = wish.negativeVotesNumber - 1
        } else {
            wish.negativeVotesNumber = wish.negativeVotesNumber + 1
            wish.positiveVotesNumber = wish.positiveVotesNumber - 1
        }
        voteElementConfig.votesUpdater([...votes.values()])
        voteElementConfig.wishUpdater(wish)
    }

    function getClassList(config) {
        if (config.voteEnabled) {
            return 'vote-element vote-enabled'
        } else {
            return 'vote-element'
        }
    }

    return (
        <div className="wishlist-item-vote-block">
            <div id={getWishVoteItemId(voteElementConfig.wishSupplier(), 'POSITIVE')} className={getClassList(voteElementConfig)}
                 onClick={handleVoteClick(voteElementConfig, 'POSITIVE')}>
                {isVoted(voteElementConfig.wishSupplier(), 'POSITIVE')
                    ? <i className="vote-value fa-solid fa-thumbs-up"/>
                    : <i className="vote-value fa-regular fa-thumbs-up"/>}
                <div className="vote-counter">{voteElementConfig.wishSupplier().positiveVotesNumber}</div>
            </div>
            <div id={getWishVoteItemId(voteElementConfig.wishSupplier(), 'NEGATIVE')} className={getClassList(voteElementConfig)}
                 onClick={handleVoteClick(voteElementConfig, 'NEGATIVE')}>
                {isVoted(voteElementConfig.wishSupplier(), 'NEGATIVE')
                    ? <i className="vote-value fa-solid fa-thumbs-down"/>
                    : <i className="vote-value fa-regular fa-thumbs-down"/>}
                <div className="vote-counter">{voteElementConfig.wishSupplier().negativeVotesNumber}</div>
            </div>
        </div>
    )
}

export default VoteElement;